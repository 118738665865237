<template>
    <v-container>
      <v-card>
        <v-card-title style="gap: 16px;" class="px-4 py-4 flex-wrap align-center justify-space-between">
          <h6 class="text-h6 primary--text text-uppercase font-weight-bold" style="word-break: break-word;">
            <NavButton style="transform: translateY(-2px)" />
            Solicitud de gestión de usuarios
          </h6>

          <div>
            <!-- Actions Request -->
            <template v-if="!mostrarAcciones">
              <v-chip 
                label 
                large
                :color="colorEstado" 
                :class="([3,4].includes(idEstadoSolicitudValue)) ? 'white--text' : ''"
                class="text-uppercase font-weight-medium py-2"
                style="height: unset !important;"
              >
                  {{ nombreEstado }}
                  {{ estadoPendiente() }}
              </v-chip>
            </template>
            <template v-else>
              <div class="d-flex justify-end flex-wrap" style="gap: 16px;">
                <v-btn
                  v-if="showActions"
                  class="text-uppercase flex-grow-1"
                  color="success"
                  outlined
                  @click="modalConfirmar = true"
                >
                  <v-icon color="success" class="mr-2">mdi-check-circle-outline</v-icon>
                  Aprobar
                </v-btn>
                <v-btn
                  v-if="showActions"
                  class="text-uppercase flex-grow-1"
                  color="error"
                  @click="modalCancelar = true"
                  outlined
                >
                  <v-icon color="error" class="mr-2">mdi-cancel</v-icon>
                  Cancelar
                </v-btn>
              </div>
            </template>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-4 py-4">
          <v-row v-if="formInfoGeneral?.tipoSolicitud?.id == 2">
            <v-col cols="12">
              <v-btn
                color="secondary"
                @click.stop="modalHistorialCambiosAbierta = true"
              >
                <v-icon class="mr-1" color="white">mdi-history</v-icon>
                Historial de cambios
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-4">
              <v-col cols="12">
                  <!-- End Actions Request -->
                  <div>
                      <InformacionGeneralComponent readonly />
                      <FormMainCuentaComponent 
                        class="mt-4" ref="form" 
                        :creador="solicitante" 
                        :estadoSolicitud="this.estadoSolicitud"
                        readonly
                      />
                      <v-row class="mb-8" v-if="this.$route.params.id && this.solicitante === this.idUsuarioEnLinea && this.estadoSolicitud === 2">
                        <v-col cols="12" sm="6" md="3">
                          <v-btn block color="primary" @click="actualizarSolicitud">Actualizar solicitud</v-btn>
                        </v-col>
                      </v-row>
                  </div>
              </v-col>
          </v-row>
        </v-card-text>
      </v-card>
        <ConfirmationDialogComponent
            :show="modalConfirmar"
            btnConfirmar="Continuar"
            title="¿Desea aprobar la solicitud?"
            message="De confirmarse la siguiente acción, los cambios no serán reversibles."
            @close="modalConfirmar = false"
            @confirm="aprobarSolicitud"
        />
        <ConfirmationDialogComponent
            ref="modalCancelar"
            :show="modalCancelar"
            :showMotivoCancelar="true"
            v-model.trim="motivoCancelar"
            btnConfirmar="Aceptar"
            title="¿Desea cancelar la solicitud?"
            message="De confirmarse la siguiente acción, los cambios no serán reversibles."
            @close="modalCancelar = false"
            @confirm="cancelarSolicitud"
        />
        <v-dialog v-if="formInfoGeneral?.tipoSolicitud?.id == 2 && stepper != 1" v-model="modalHistorialCambiosAbierta" max-width="1000px" persistent>
          <v-card style="position: relative;">
              <v-card-title class="px-0 py-0 flex-column" style="position: sticky; top: 0; z-index: 2; background: #fff;">
                <div class="d-flex px-4 py-4" style="width: 100%">
                  <v-btn 
                      style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                      class="pt-0"
                      @click.stop="modalHistorialCambiosAbierta = false" 
                      text
                      rounded
                  >
                      <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </div>
                <v-divider style="width: 100%"  />
              </v-card-title>
              <v-card-text class="px-4 pt-4 pb-0">
                <div class="px-1 py-1">
                  <ModalHistoriaCambiosComponent :idUsuario="idUsuario" />
                </div>
                <div 
                  class="d-flex justify-center align-center py-4" 
                  style="position: sticky; bottom: 0px; gap: 16px; z-index: 1; background: #FFF;"
                >
                  <v-btn 
                    class="flex-grow-1 flex-shrink-1" 
                    color="primary" 
                    large
                    @click.stop="modalHistorialCambiosAbierta = false"
                  >
                    Cerrar
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import ModalHistoriaCambiosComponent from "./components/ModalHistoriaCambiosComponent.vue";
import ConfirmationDialogComponent from "../../components/ConfirmationDialogComponent.vue";
import InformacionGeneralComponent from "./components/InformacionGeneralComponent.vue";
import FormMainCuentaComponent from "./components/FormMainCuentaComponent.vue";
import ChatComponent from "../../components/ChatComponent.vue";
import NavButton from "@/components/utils/NavButton.vue";
import { mapMutations, mapState } from "vuex";
import moment from "moment";

export default {
  components: {
    ModalHistoriaCambiosComponent,
    ChatComponent,
    ConfirmationDialogComponent,
    InformacionGeneralComponent,
    FormMainCuentaComponent,
    NavButton,
  },
  data: () => ({
    motivoCancelar: null,
    showActions: true,
    modalConfirmar: false,
    modalCancelar: false,
    idUsuario: null,
    estadoLog: null,
    idEstadoSolicitud: null,
    mostrarAcciones: false,
    observando: false,
    solicitante: null,
    puedeVerObservacion: null,
    modalHistorialCambiosAbierta: false,
  }),
  computed: {
    ...mapState({
      idUsuarioEnLinea: (state) =>
        state.userInfo.user.id,
      formMainCuenta: (state) => 
        state.solicitudCuenta.formMainCuenta,
      sockets: (state) => state.sockets,
    }),
    ...mapState("solicitudCuenta", ["formInfoGeneral", "formMainCuenta", "estadoSolicitud"]),
    colorEstado() {
      if (this.estadoLog) {
        return this.estadoLog[0]?.id_estado_solicitud_usuario?.color;
      }
    },
    nombreEstado() {
      return this.estadoLog?.[0].id_estado_solicitud_usuario.nombre ?? '-';
    },
    observacion() {
      return this.estadoLog?.[0].observacion;
    },
    idEstadoSolicitudValue() {
      return this.estadoLog?.[0].id_estado_solicitud_usuario.id ?? 0;
    },
    idSolicitud() {
      return parseInt(this.$route.params?.id);
    }
  },
  methods: {
    ...mapMutations("solicitudCuenta", [
      "setFormInfoGeneral",
      "setFormMainCuenta",
      "setEstadoSolicitud",
      "resetState",
    ]),
    verifyPreview(perfilActual) {
      if ([20, 34].includes(this.formMainCuenta?.rolPerfilUsuario?.id)) return false;

      console.log('perfilActual :>> ', perfilActual);
      if ([20, 34].includes(perfilActual)) return false;

      const hasAllowedRoles = this.haveRoles([
        "ROLE_SOLICITUD_USUARIO_APROBADO_UACI",
        "ROLE_SOLICITUD_USUARIO_APROBADO_TITULAR",
        "ROLE_SOLICITUD_USUARIO_APROBADO_DIRECCION_COMPRAS",
      ]);

      if (!hasAllowedRoles) return false;

      const nivelDeAprobacion = this.estadoLog[0]?.id_nivel_aprob_usuario?.id ?? null;
      const miPerfil = this.definePerfil();
      const perfiles = {
        uaci: [null],
        titular: [1, 2],
        direccion_compras: [2],
      };

      // Validaciones
      if ([3, 4].includes(this.idEstadoSolicitud)) return false;

      return (perfiles[miPerfil.dependencia].includes(nivelDeAprobacion) && this.idEstadoSolicitud == 1);
    },
    definePerfil() {
      if (this.haveRole("ROLE_SOLICITUD_USUARIO_APROBADO_UACI")) return { id: 1, dependencia: "uaci" };

      return this.haveRoles(["ROLE_SOLICITUD_USUARIO_APROBADO_TITULAR", "ROLE_SOLICITUD_USUARIO_APROBADO_DIRECCION_COMPRAS"])
        ? { id: 2, dependencia: "titular" }
        : null;
    },
    estadoPendiente() {
      if (!this.estadoLog) return null;

      return this.estadoLog[0].id_estado_solicitud_usuario.id == 1
      ? null
      : this.estadoLog[0].id_nivel_aprob_usuario.nombre;
    },
    async aprobarSolicitud() {

      try {
        let response;
        if (this.formInfoGeneral?.tipoSolicitud?.id == 1) {
          response = await this.services.Usuarios.postCreateUser(
            this.$route.params.id,
            { id_estado_solicitud: 3 }
          );
        } else if (this.formInfoGeneral?.tipoSolicitud?.id == 2) {
          response = await this.services.Usuarios.putModifyUser(
            this.$route.params.id,
            { id_estado_solicitud: 3 }
          );
        }
        if (response) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: response?.data?.message || "Solicitud aprobada",
          });
          this.$router.push({
            name: "listado-usuarios-solicitudes",
          });
        }
      } catch (error) {
        console.log(error);
      }

    },
    async cancelarSolicitud() {

      let valid = this.$refs.modalCancelar.verifyMotivoCancelar();
      if (valid) {
        try {
          let response;
          if (this.formInfoGeneral?.tipoSolicitud?.id == 1) {
            response = await this.services.Usuarios.postCreateUser(
              this.$route.params.id,
              { id_estado_solicitud: 4, motivo: this.motivoCancelar }
            );
          } else if (this.formInfoGeneral?.tipoSolicitud?.id == 2) {
            response = await this.services.Usuarios.putModifyUser(
              this.$route.params.id,
              { id_estado_solicitud: 4, motivo: this.motivoCancelar }
            );
          }
          if (response) {
            this.$router.push({
              name: "listado-usuarios-solicitudes",
            });
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        this.temporalAlert({
          show: true,
          type: "error",
          message: "Debe ingresar un motivo válido para cancelar la solicitud",
        });
      }

    },
    async observarSolicitud(payload) {
      if (payload?.referencia && payload?.mensaje !== "" && this.observando === true) {
        this.services.Usuarios.observarSolicitud({
          idSolicitud: payload.referencia,
          observacion: payload.mensaje,
        }).then(() => {
          this.observando = false;
          this.mostrarAcciones = false;
          this.obtainSolicitud();
        }).catch((e) => {
          console.log(e);

          this.temporalAlert({
            show: true,
            type: 'error',
            message: 'No se ha podido actualizar el estado de la solicitud'
          })
        });
      }
    },
    openModalChat() {
      this.$refs.chatModal.openChat();
    },
    async obtainSolicitud() {

      try {
        const response = await this.services.Usuarios.getSolicitudById(
          this.$route.params.id
        );
        if (response) {
          this.idUsuario = response?.data?.solicitudUsuario?.id_usuario_modificado;
          this.estadoLog = response?.data?.solicitudUsuario?.estado_log;
          this.idEstadoSolicitud = response?.data?.solicitudUsuario?.id_estado_solicitud;
          this.solicitante = response?.data?.solicitudUsuario?.id_solicitante;
          this.setEstadoSolicitud(response?.data?.solicitudUsuario?.id_estado_solicitud);
          this.setFormInfoGeneral({
            apellidoCasada: response?.data?.solicitudUsuario?.apellido_casada,
            nacionalidad: response?.data?.solicitudUsuario?.nacionalidad,
            tipoSolicitud: response?.data?.solicitudUsuario?.detalle_tipo_solicitud,
            numeroDocumento: response?.data?.solicitudUsuario?.documento,
            primerApellido: response?.data?.solicitudUsuario?.primer_apellido,
            primerNombre: response?.data?.solicitudUsuario?.primer_nombre,
            segundoApellido: response?.data?.solicitudUsuario?.segundo_apellido,
            segundoNombre: response?.data?.solicitudUsuario?.segundo_nombre,
            estadoUsuario: !response?.data?.solicitudUsuario?.estado_usuario,
          });
          this.setFormMainCuenta({
            cargoUsuario: response?.data?.solicitudUsuario?.cargo_funcional,
            stckUnidades: response?.data?.solicitudUsuario?.unidades,
            divisionUsuario:
              response?.data?.solicitudUsuario?.unidades.length == 1
                ? response?.data?.solicitudUsuario?.unidades[0]
                : null,
            emailUsuario: response?.data?.solicitudUsuario?.correo_electronico,
            fechaCaducidadUsuario: moment(
              response?.data?.solicitudUsuario?.fecha_caducidad
            ).format("DD/MM/YYYY"),
            infoAdicionalUsuario: response?.data?.solicitudUsuario?.informacion_adicional,
            profesionUsuario: response?.data?.solicitudUsuario?.profesion,
            rolPerfilUsuario: response?.data?.solicitudUsuario?.rol_perfil,
            telefonoUsuario: response?.data?.solicitudUsuario?.telefono,
            urlDocumentoInstitucional:
              response?.data?.solicitudUsuario?.url_documento_institucional,
            detalleModificacionUsuario:
              response?.data?.solicitudUsuario?.observaciones_modificacion,
          });
          this.puedeVerObservacion = response?.data?.puedeVerObservacion;
        }
        this.mostrarAcciones = this.verifyPreview(response?.data?.perfil_actual?.id_perfil);
      } catch (error) {
        console.log(error);
      }

    },
    async actualizarSolicitud() {

      // ->
      if (
        this.formMainCuenta?.stckUnidades.length == 0 &&
        this.formMainCuenta?.divisionUsuario != null
      ) {
        this.$store.commit(
          "solicitudCuenta/addUnidadUsuario",
          this.formMainCuenta?.divisionUsuario
        );
      } else if (
        this.formMainCuenta?.stckUnidades.length == 1 &&
        this.formMainCuenta?.divisionUsuario != null
      ) {
        if (
          this.formMainCuenta?.stckUnidades[0].id !=
          this.formMainCuenta?.divisionUsuario.id
        ) {
          this.cleanUnidadesUsuario();
          this.$store.commit(
            "solicitudCuenta/addUnidadUsuario",
            this.formMainCuenta?.divisionUsuario
          );
        }
      }
      // <-
      let urlDocumento = null;

      if (this.formMainCuenta?.tituloUsuario != null) {
        // PETICION PARA RETORNAR URL DE ARCHIVO
        urlDocumento = await this.obtainUrlDocumento();
        if (urlDocumento == null) {

          this.temporalAlert({
            show: true,
            type: "error",
            message:
              "Error al subir el documento, intente nuevamente. Si el error persiste contacte al administrador",
          });
          return;
        }
      }

      if (this.formInfoGeneral?.tipoSolicitud?.id == 1) {
        // PETICION A BACK END DE CREACION DE USUARIO
        try {
          let params = {
            id_tipo_solicitud: this.formInfoGeneral?.tipoSolicitud?.id,
            nacionalidad: this.formInfoGeneral?.nacionalidad?.id_tipo_documento,
            numero_documento: this.formInfoGeneral?.numeroDocumento,
            primer_nombre: this.formInfoGeneral?.primerNombre,
            segundo_nombre: this.formInfoGeneral?.segundoNombre
              ? this.formInfoGeneral?.segundoNombre
              : undefined,
            primer_apellido: this.formInfoGeneral?.primerApellido,
            segundo_apellido: this.formInfoGeneral?.segundoApellido
              ? this.formInfoGeneral?.segundoApellido
              : undefined,
            apellido_casada: this.formInfoGeneral?.apellidoCasada
              ? this.formInfoGeneral?.apellidoCasada
              : undefined,
            correo_electronico: this.formMainCuenta?.emailUsuario,
            telefono: this.formMainCuenta?.telefonoUsuario,
            profesion: this.formMainCuenta?.profesionUsuario?.id,
            rol_perfil: this.formMainCuenta?.rolPerfilUsuario?.id,
            fecha_caducidad: this.moment(
              this.formMainCuenta?.fechaCaducidadUsuario,
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD HH:mm:ss"),
            cargo_funcional: this.formMainCuenta?.cargoUsuario,
            informacion_adicional: this.formMainCuenta?.infoAdicionalUsuario
              ? this.formMainCuenta?.infoAdicionalUsuario
              : undefined,
            unidades: this.formMainCuenta?.stckUnidades,
          };

          if (urlDocumento !== null) params.url_documento_institucional = urlDocumento;

          const response = await this.services.Usuarios.updateSolicitudUsuario(this.$route.params.id, params);
          if (response) {
            this.$router.push({ name: "listado-usuarios-solicitudes" });
          }
        } catch (error) {

          console.log(error);
        }
      } else if (this.formInfoGeneral?.tipoSolicitud?.id == 2) {
        // PETICION A BACK END DE MODIFICACION DE USUARIO
        try {
          let params = {
            id_tipo_solicitud: this.formInfoGeneral?.tipoSolicitud?.id,
            nacionalidad: this.formInfoGeneral?.nacionalidad?.id_tipo_documento,
            numero_documento: this.formInfoGeneral?.numeroDocumento,
            primer_nombre: this.formInfoGeneral?.primerNombre,
            segundo_nombre: this.formInfoGeneral?.segundoNombre
              ? this.formInfoGeneral?.segundoNombre
              : undefined,
            primer_apellido: this.formInfoGeneral?.primerApellido,
            segundo_apellido: this.formInfoGeneral?.segundoApellido
              ? this.formInfoGeneral?.segundoApellido
              : undefined,
            apellido_casada: this.formInfoGeneral?.apellidoCasada
              ? this.formInfoGeneral?.apellidoCasada
              : undefined,
            // ->
            correo_electronico: this.formMainCuenta?.emailUsuario,
            telefono: this.formMainCuenta?.telefonoUsuario,
            profesion: this.formMainCuenta?.profesionUsuario?.id,
            rol_perfil: this.formMainCuenta?.rolPerfilUsuario?.id,
            fecha_caducidad: this.moment(
              this.formMainCuenta?.fechaCaducidadUsuario,
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD HH:mm:ss"),
            cargo_funcional: this.formMainCuenta?.cargoUsuario,
            informacion_adicional: this.formMainCuenta?.infoAdicionalUsuario
              ? this.formMainCuenta?.infoAdicionalUsuario
              : undefined,
            unidades: this.formMainCuenta?.stckUnidades,
            id_usuario_modificado: this.idUsuario,
            estado_usuario: (this.formInfoGeneral?.estadoUsuario === null) ? false : this.formInfoGeneral?.estadoUsuario,
            observaciones_modificacion:
              this.formMainCuenta?.detalleModificacionUsuario,
          };

          if (urlDocumento || this.formMainCuenta?.urlDocumentoInstitucional) params.url_documento_institucional = urlDocumento
              ? urlDocumento
              : this.formMainCuenta?.urlDocumentoInstitucional;

          const response = await this.services.Usuarios.updateSolicitudUsuario(this.$route.params.id, params);
          if (response) {
            this.$router.push({ name: "listado-usuarios-solicitudes" });
          }
        } catch (error) {
          console.log(error);

        }
      }

    },
    async obtainUrlDocumento() {
      try {
        let formData = new FormData();
        formData.append(
          "documento_institucional",
          this.formMainCuenta?.tituloUsuario
        );
        const response = await this.services.Usuarios.postDocumento(formData);
        if (response) {
          return response?.data?.url_documento_cargo;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.$watch("$refs.chatModal.dilogMessage", (value) => {
      this.showActions = !value;
    });
  },
  created() {
    this.obtainSolicitud();
  },
  beforeDestroy() {
    this.resetState();
  },
};
</script>

<style></style>
