<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div class="d-flex align-center">
          <v-icon color="secondary"> mdi-history </v-icon>
          <span class="secondary--text px-3"> Histórico de cambios </span>
        </div>
        <template v-slot:actions>
          <v-icon color="secondary"> $expand </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-expansion-panels>
          <v-expansion-panel
            id="exp_detail"
            v-for="(item, i) in historialCambios"
            :key="i"
          >
            <v-expansion-panel-header>
              {{ moment(item.fecha, "YYYY-MM-DD").format("DD/MM/YYYY") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-for="(detailItem, j) in item.cambios" :key="j">
                <template v-if="Object.keys(detailItem.version_actualizada).length === 0">
                  <div class="text-center">
                    <span class="info--text">
                      No se realizaron cambios en el registro
                    </span>
                  </div>
                </template>
                <template v-else>
                  <v-divider />
                  <div
                    v-for="(childDetailItem, k) in detailItem.version_actualizada"
                    :key="k"
                  >
                    <div class="my-6">
                      <span class="text--secondary">
                        {{ childDetailItem.descripcion_campo }}
                      </span>
                      <div class="d-flex flex-column text--secondary">
                        <span class="old_value py-1 px-3 my-1 rounded-lg text-wrap">
                          {{ childDetailItem?.descripcion_campo == "Id unidad" ? `${childDetailItem?.valor_actual[0]?.id} - ${childDetailItem?.valor_actual[0]?.nombre}` : childDetailItem.valor_actual }}
                        </span>
                        <span class="new_value py-3 px-3 my-1 rounded-lg text-wrap">
                          {{ childDetailItem?.descripcion_campo == "Id unidad" ? `${childDetailItem?.valor_nuevo[0]?.id} - ${childDetailItem?.valor_nuevo[0]?.nombre}` : childDetailItem.valor_nuevo }}
                          
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import moment from "moment";
export default {
  name: "ModalHistoriaCambiosComponent",
  props: {
    idUsuario: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    historialCambios: [],
  }),
  methods: {
    moment,
    async obtainHistorial() {
      try {
        const response = await this.services.Usuarios.getHistoricoCambios(
          this.idUsuario
        );

        console.log('response :>> ', response);
        if (response) {
          this.historialCambios = response?.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // verifyEmptyObject(data) {
    //   return Object.keys(data[0].version_actualizada).length === 0;
    // },
  },
  watch: {
    idUsuario: {
      handler(value) {
        if (value) this.obtainHistorial();
      },
      immediate: true,
    }
  }
  // created() {
  //   this.obtainHistorial();
  // },
};
</script>

<style lang="scss" scoped>
.old_value {
  background-color: rgba(255, 0, 0, 0.25);
}
.new_value {
  background-color: rgba(0, 255, 55, 0.25);
}
:deep(.v-expansion-panel-content__wrap) {
  padding: 16px 8px !important;
}
#exp_detail::before {
  box-shadow: none;
}
</style>